import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: () => import('../views/homePage.vue'),
    meta: {
      title: "首页",
      keepAlive: false, // 不需要缓存
    }
  }, {
    path: '/answer',
    name: 'answer',
    component: () => import('../views/answer.vue'),
    meta: {
      title: "答题",
      keepAlive: false, // 不需要缓存
    }
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('../views/mine.vue'),
    meta: {
      title: "我的",
      keepAlive: false, // 不需要缓存
    }
  },
  {
    path: '/Bankcard',
    name: 'Bankcard',
    component: () => import('../views/Bankcard.vue'),
    meta: {
      title: "银行卡",
      keepAlive: false, // 不需要缓存
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  //页面跳转显示在顶部   
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
