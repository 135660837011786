import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// 封装axios
import axios from 'axios'
Vue.prototype.$axios = axios
import qs from 'qs'
Vue.prototype.$qs = qs

//导入全局请求
import request from '@/utils/request.js';
Vue.prototype.$request = request.request;
Vue.prototype.BASE_URL = request.BASE_URL

// npm install amfe-flexible --save // 安装 amfe-flexible 
// npm i postcss-pxtorem@5.1.1  // 自动将px转换为rem
import 'amfe-flexible/index.js'

// 引入全部组件
import vant from 'vant';
import 'vant/lib/index.css';
import 'vant/es/toast/style';
Vue.use(vant)

// jquery
// npm install --save jquery
import $ from 'jquery'
Vue.prototype.$ = $


// 防止连点
Vue.directive('throttle', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      el.style.pointerEvents = 'none';
      if (!el.disabled) {
        setTimeout(() => {
          el.style.pointerEvents = 'auto'
        }, binding.value || 1000)
      }
    })
  }
})

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
