<template>
  <div id="app">
      <router-view :style="'min-height:' + height + 'px;'" v-if="isRouterAlive"></router-view>
    <audio controls="controls" :src="url" ref="audio" loop :autoplay="true" hidden></audio>
  </div>
</template>
<script>
export default {
  data() {
    return {
      height: "",
      isRouterAlive: true,
      url: "",
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.height = document.documentElement.clientHeight
      }, 100);
    })

    this.getDispose()
  },
  methods: {

    /**
     * 获取配置项
     * 刘嘉鑫
     * 2023-3-6
     */
    getDispose() {
      this.$request({
        url: "/Index/getConfig",
      }).then(res => {
        // console.log(res)
        this.url = res.data.answer_music
        this.$store.state.dispose = res.data;
      })
    },

    reload() {
      console.log("刷新当前页面");
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="scss">
/*每个页面公共css */
@import "./assets/css/common.css";
</style>
