//存放主站域名
const BASE_URL = 'http://dati.xingnuo.vip/userapi'


//生成请求方法
function request(params) { // 传参：params参数
    let {
        url, //	请求地址
        data = {}, //	参数信息
        method = 'POST', //	请求方式
        headers = {
            'content-type': 'application/json' // application/x-www-form-urlencoded
        }, //	请求头信息
    } = params
    return new Promise((resolve, reject) => {
        data.token = localStorage.getItem("token") == 'null' ? '' : localStorage.getItem("token")
        this.$axios({
            method: method,
            headers: headers, // 请求头
            url: BASE_URL + url,
            data: data,
            // data: this.$qs.stringify(data),
            xhrFields: {
                withCredentials: true
            }
        }).then((res) => {
            // 获取接口状态
            const soleData = res.data
            const {
                code
            } = soleData
            if (code == 1) {
                resolve(soleData)
            } else {
                reject(soleData)
            }
        }).catch(error => {
            reject(error)
        })
    })
}

module.exports = {
    BASE_URL,
    request
}